import React from 'react';
import styled from 'styled-components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Regular } from '../components/text';

const Bold = styled.span`
  font-weight: 500;
`;

const Hyperlink = ({ children, href }) => (
  <Regular
    as="a"
    href={href}
    colored="nbPrimary"
    target={href.startsWith('#') ? null : '_blank'}
    rel="noopener noreferrer">
    {children}
  </Regular>
);

const BodyCopy = styled(Regular)``;

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <>
        <BodyCopy as="p">{children}</BodyCopy>
        <br />
      </>
    ),
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol>{children}</ol>;
    },
    [INLINES.HYPERLINK]: item => (
      <Hyperlink href={item.data.uri}>{item.content[0].value}</Hyperlink>
    ),
  },
  renderText: text => {
    return <span className="spanText">{text}</span>;
  },
};

export const renderRichText = richTextJson => documentToReactComponents(richTextJson, options);
